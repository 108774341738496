import _ from '@/utils/lodash'
import { likeRegExp } from '@/offline/database'

export default {
  _selectMaterialInstalarBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        ...Vue.$offline.lparteTrabajo.columns,
        ...Vue.$offline.parteTrabajo.columns,
        ...Vue.$offline.maccion.columns,
        ...Vue.$offline.articulo.columns,
        ...Vue.$offline.tsubsis.columns,
        tables.subsis.descripcion.as('desc_subsis'),
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.subsis,
        tables.lparte_trabajo.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.lparte_trabajo.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .innerJoin(
        tables.articulo,
        tables.lparte_trabajo.idarticulo.eq(tables.articulo.idarticulo)
      )
      .leftOuterJoin(
        tables.accion_ejec,
        tables.lparte_trabajo.idaccion_ejec.eq(tables.accion_ejec.idaccion_ejec)
      )
      .leftOuterJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
  },
  selectMaterialInstalarMetadata (sorter) {
    const metadata = {
      sorter_desc: [
        { label: 'Nº de serie', name: 'nserie', field: 'lparte_trabajo.nserie' },
        { label: 'Nº de elemento', name: 'nelemento', field: 'lparte_trabajo.nelemento' },
        { label: 'Código', name: 'codigo', field: 'lparte_trabajo.codigo' },
        { label: 'Descripción', name: 'descripcion', field: 'lparte_trabajo.descripcion' },
      ],
    }
    if (!_.isEmpty(sorter)) {
      metadata.sorter = sorter
    } else {
      metadata.sorter = [
        { field: 'nelemento', asc: true },
      ]
    }
    return metadata
  },
  async selectMaterialInstalar (Vue, filter, search, sorter, page, idparteTrabajo, idsistema) {
    const tables = Vue.$offline.db.tables
    let metadata = this.selectMaterialInstalarMetadata(sorter)
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      whereSearch = Vue.$offline.lparteTrabajo.searchFilter(search)
    }
    if (filter.codigo.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.codigo.match(likeRegExp(filter.codigo.value))
        )
      )
    }
    if (filter.nserieObligado.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.articulo.nserie_obligado.eq(filter.nserieObligado.value)
        )
      )
    }
    if (filter.nserieVacio.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.nserie.isNull(filter.nserieVacio.value)
        )
      )
    }
    if (filter.descripcion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.descripcion.match(likeRegExp(filter.descripcion.value))
        )
      )
    }
    if (filter.nelemento.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.lparte_trabajo.nelemento.match(likeRegExp(filter.nelemento.value))
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo),
        tables.subsis.idsubsis.eq(idsistema),
        ...whereSearch,
        ...whereFilter
      )
    )
    let query = this._selectMaterialInstalarBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
    query = Vue.$offline.db.applySorter(query, metadata)
    const rows = await query.exec()
    return [rows, metadata]
  },
  selectMaterialInstalarRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return this._selectMaterialInstalarBase(Vue)
      .where(tables.lparte_trabajo.idlparte_trabajo.in(pks))
      .exec()
  },
  async rowSubsis (Vue, idsubsis) {
    const tables = Vue.$offline.db.tables
    const query = await Vue.$offline.db
      .select()
      .from(tables.subsis)
      .innerJoin(
        tables.sistema,
        tables.subsis.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .where(tables.subsis.idsubsis.eq(idsubsis))
      .exec()
    return query[0]
  }
}
